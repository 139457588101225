


// Template Map
export default {
  '__react_static_root__/src/containers/404.js': require('__react_static_root__/src/containers/404.js').default,
'__react_static_root__/src/containers/about/About.js': require('__react_static_root__/src/containers/about/About.js').default,
'__react_static_root__/src/containers/account/AccountCompletion.js': require('__react_static_root__/src/containers/account/AccountCompletion.js').default,
'__react_static_root__/src/containers/account/AccountVerificationFailed.js': require('__react_static_root__/src/containers/account/AccountVerificationFailed.js').default,
'__react_static_root__/src/containers/account/AccountVerificationSuccess.js': require('__react_static_root__/src/containers/account/AccountVerificationSuccess.js').default,
'__react_static_root__/src/containers/account/BookADemo.js': require('__react_static_root__/src/containers/account/BookADemo.js').default,
'__react_static_root__/src/containers/account/ForgotPassword.js': require('__react_static_root__/src/containers/account/ForgotPassword.js').default,
'__react_static_root__/src/containers/account/InvitationFailed.js': require('__react_static_root__/src/containers/account/InvitationFailed.js').default,
'__react_static_root__/src/containers/account/Launchpad.js': require('__react_static_root__/src/containers/account/Launchpad.js').default,
'__react_static_root__/src/containers/account/Login.js': require('__react_static_root__/src/containers/account/Login.js').default,
'__react_static_root__/src/containers/account/LoginWithGoogle.js': require('__react_static_root__/src/containers/account/LoginWithGoogle.js').default,
'__react_static_root__/src/containers/account/Logout.js': require('__react_static_root__/src/containers/account/Logout.js').default,
'__react_static_root__/src/containers/account/Signup.js': require('__react_static_root__/src/containers/account/Signup.js').default,
'__react_static_root__/src/containers/account/UpdatePassword.js': require('__react_static_root__/src/containers/account/UpdatePassword.js').default,
'__react_static_root__/src/containers/account/verify/AccountVerify.js': require('__react_static_root__/src/containers/account/verify/AccountVerify.js').default,
'__react_static_root__/src/containers/ai/index.js': require('__react_static_root__/src/containers/ai/index.js').default,
'__react_static_root__/src/containers/ai/navbar/NavbarAI.js': require('__react_static_root__/src/containers/ai/navbar/NavbarAI.js').default,
'__react_static_root__/src/containers/ai/products/AnswerBot/AnswerBot.js': require('__react_static_root__/src/containers/ai/products/AnswerBot/AnswerBot.js').default,
'__react_static_root__/src/containers/contactUs/ContactUs.js': require('__react_static_root__/src/containers/contactUs/ContactUs.js').default,
'__react_static_root__/src/containers/customers/CaseStudy.js': require('__react_static_root__/src/containers/customers/CaseStudy.js').default,
'__react_static_root__/src/containers/customers/Customers.js': require('__react_static_root__/src/containers/customers/Customers.js').default,
'__react_static_root__/src/containers/customers/CustomersSlidingBar.js': require('__react_static_root__/src/containers/customers/CustomersSlidingBar.js').default,
'__react_static_root__/src/containers/engage/Engage.js': require('__react_static_root__/src/containers/engage/Engage.js').default,
'__react_static_root__/src/containers/events/EventDetails.js': require('__react_static_root__/src/containers/events/EventDetails.js').default,
'__react_static_root__/src/containers/events/Events.js': require('__react_static_root__/src/containers/events/Events.js').default,
'__react_static_root__/src/containers/features/Feature.js': require('__react_static_root__/src/containers/features/Feature.js').default,
'__react_static_root__/src/containers/features/Features.js': require('__react_static_root__/src/containers/features/Features.js').default,
'__react_static_root__/src/containers/home/CustomerLogos.js': require('__react_static_root__/src/containers/home/CustomerLogos.js').default,
'__react_static_root__/src/containers/home/Home.js': require('__react_static_root__/src/containers/home/Home.js').default,
'__react_static_root__/src/containers/inbox/Inbox.js': require('__react_static_root__/src/containers/inbox/Inbox.js').default,
'__react_static_root__/src/containers/lite/Lite.js': require('__react_static_root__/src/containers/lite/Lite.js').default,
'__react_static_root__/src/containers/lite/pricing/LitePricingWrapper.js': require('__react_static_root__/src/containers/lite/pricing/LitePricingWrapper.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/CURLSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/CURLSampleCode.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/NodeSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/NodeSampleCode.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/PHPSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/PHPSampleCode.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/RESTSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/RESTSampleCode.js').default,
'__react_static_root__/src/containers/loyalty/Loyalty.js': require('__react_static_root__/src/containers/loyalty/Loyalty.js').default,
'__react_static_root__/src/containers/people/People.js': require('__react_static_root__/src/containers/people/People.js').default,
'__react_static_root__/src/containers/policies/Gdpr.js': require('__react_static_root__/src/containers/policies/Gdpr.js').default,
'__react_static_root__/src/containers/policies/Privacy.js': require('__react_static_root__/src/containers/policies/Privacy.js').default,
'__react_static_root__/src/containers/policies/Terms.js': require('__react_static_root__/src/containers/policies/Terms.js').default,
'__react_static_root__/src/containers/pricing/CountrySelector.js': require('__react_static_root__/src/containers/pricing/CountrySelector.js').default,
'__react_static_root__/src/containers/pricing/PackageSMS.js': require('__react_static_root__/src/containers/pricing/PackageSMS.js').default,
'__react_static_root__/src/containers/pricing/Pricing.js': require('__react_static_root__/src/containers/pricing/Pricing.js').default,
'__react_static_root__/src/containers/pricing/PricingSMS.js': require('__react_static_root__/src/containers/pricing/PricingSMS.js').default,
'__react_static_root__/src/containers/pricing/SignUpData.js': require('__react_static_root__/src/containers/pricing/SignUpData.js').default,
'__react_static_root__/src/containers/senderIdVerify/VerifyEmailFailed.js': require('__react_static_root__/src/containers/senderIdVerify/VerifyEmailFailed.js').default,
'__react_static_root__/src/containers/senderIdVerify/VerifyEmailSuccess.js': require('__react_static_root__/src/containers/senderIdVerify/VerifyEmailSuccess.js').default,
'__react_static_root__/src/containers/sms/SMS.js': require('__react_static_root__/src/containers/sms/SMS.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/EmailPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/EmailPage.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/MessengerPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/MessengerPage.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/SmsPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/SmsPage.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/WhatsAppPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/WhatsAppPage.js').default,
'__react_static_root__/src/containers/solutions/Church.js': require('__react_static_root__/src/containers/solutions/Church.js').default,
'__react_static_root__/src/containers/solutions/Education.js': require('__react_static_root__/src/containers/solutions/Education.js').default,
'__react_static_root__/src/containers/solutions/Industries.js': require('__react_static_root__/src/containers/solutions/Industries.js').default,
'__react_static_root__/src/containers/solutions/Influencer.js': require('__react_static_root__/src/containers/solutions/Influencer.js').default,
'__react_static_root__/src/containers/solutions/Wedding.js': require('__react_static_root__/src/containers/solutions/Wedding.js').default,
'__react_static_root__/src/containers/useCases/Saaraketha.js': require('__react_static_root__/src/containers/useCases/Saaraketha.js').default,
'__react_static_root__/src/containers/ai': require('__react_static_root__/src/containers/ai').default,
'__react_static_root__/src/containers/ai/products/AnswerBot/AnswerBot': require('__react_static_root__/src/containers/ai/products/AnswerBot/AnswerBot').default
}

export const notFoundTemplate = '__react_static_root__/src/containers/404.js'

